<template>
  <div class="img-list">
    <div
      class="img-item"
      v-for="(item, index) in mgList"
      :key="item.img + index"
    >
      <div class="myimg">
        <div :id="'player' + item.img"></div>
      </div>
      <div class="myname">{{ index + 1 + "." + item.name }}</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dataList: [],
      vodPlayerJs: "https://player.polyv.net/script/player.js",
      mgList: [
						{ img: '73e14fa217b69117ca074c4f90a0b81f_7', name: '波动偏离度平仓策略' },
						{ img: '73e14fa21722e960cc208d6cabf680bb_7', name: '创建交易系统实例' },
						{ img: '73e14fa217414ca1db033c6d662f2107_7', name: '分类思维' },
						{ img: '73e14fa21748280cbd1efa8938ff4797_7', name: '交易的一致性' },
						{ img: '73e14fa217050fcdf5e4164a3b28fadd_7', name: '交易理念与交易系统(1)' },
						{ img: '73e14fa217b974b955a745bbb856efbe_7', name: '交易系统的构建' },
						{ img: '73e14fa217b2a325a5306adea4440a8d_7', name: '交易系统的特征' },
						{ img: '73e14fa2176bc4952abbdd602f169fde_7', name: '交易系统的误区' },
						{ img: '73e14fa21724b0fd521c6746ffdf049b_7', name: '交易系统的优势' },
						{ img: '73e14fa2177d4277a4d487fffeea042e_7', name: '交易系统的原则' },
						{ img: '73e14fa2179c62bcdd25aae1823547fd_7', name: '交易系统模块' },
						{ img: '73e14fa2177fe440c8f5b5e55d4994f2_7', name: '交易心态与交易系统' },
						{ img: '73e14fa217a91e24306cb982a88902c3_7', name: '均线偏离度平仓' },
						{ img: '73e14fa217d3560c57000ca4f42fa730_7', name: '平仓2' },
						{ img: '73e14fa2179c0673985c5641a4b8ca77_7', name: '平仓策略比较' },
						{ img: '73e14fa21781ad65a387591ad4cebd9c_7', name: '趋势跟踪交易系统实例' },
						{ img: '73e14fa2170ae5e30499088f54ad4b53_7', name: '移动平仓策略' },
						{ img: '73e14fa217d38b31cac6fde6be6be790_7', name: '最大回撤率' },
						{ img: '73e14fa2173b81a660225ca513c02e12_7', name: 'ATR应用.王英敏' },
						{ img: '73e14fa217d964a734afd50ad4a6e62e_7', name: '真实波幅均值.戚佳慧' },
						{ img: '73e14fa217dbb1e4fb370f3d3676621f_7', name: '风险的类型' },
						{ img: '73e14fa21791f6de5d5265d6dffebe02_7', name: '资金回测' },
						{ img: '73e14fa217261dfb60c027d70003204a_7', name: '资金管理策略' },
						{ img: '73e14fa217de8df82407e34bf755cd8c_7', name: '获胜率' },
						{ img: '73e14fa217f37a119092a6971c894633_7', name: '凯利公式' },
						{ img: '73e14fa2171bc747744da10897f105b1_7', name: '贝塔系数' },
						{ img: '73e14fa2173c8705e2737050e2f39f46_7', name: '标准差' },
						{ img: '73e14fa217eb2e53417f9d195f43dbe5_7', name: '标准差应用' },
						{ img: '73e14fa217c27f45110d77e8e7a0bd60_7', name: '交易过程' },
						{ img: '73e14fa21703d696b181566ba014e03b_7', name: '交易系统' },
						{ img: '73e14fa2177606d4b02e1bf28531c9a6_7', name: '交易系统的类型' },
						{ img: '73e14fa2177fd260d5e11af2689a9938_7', name: '交易系统的三大支柱' },
						{ img: '73e14fa217c0fcf1ec7a827b2eef7bd9_7', name: '交易者的类型' },
						{ img: '73e14fa21753cc45066a0b0250c8c54f_7', name: '为什么散户容易割韭菜' },
						{ img: '73e14fa21732d469d2da03d699abf242_7', name: '夏普利率' },
						{ img: '73e14fa2177355512dd99930d1ad66eb_7', name: '资金管理的模型' },
						{ img: '73e14fa217dfe59ce1cbb0ded9af7a0f_7', name: '资金管理' },
						{ img: '73e14fa217055f390e7f4721d1dfe619_7', name: '仓位管理策略' },
						{ img: '73e14fa217b7c11d6db4dd0c98673939_7', name: '仓位管理' },
						{ img: '73e14fa217f3e2bead70f360a63a63f4_7', name: '交易计划' },
						{ img: '73e14fa217b7c8e6a5db3cf30a17de3b_7', name: '巴菲特止损吗' },
						{ img: '73e14fa217d99f8afe78078320d51f7f_7', name: '为什么止损这么难' },
						{ img: '73e14fa2178cf2d09cb76945787b9e9e_7', name: '为什么止损这么难2' },
						{ img: '73e14fa217c70b9ea4b1137608f477ec_7', name: '策略因子含义及作用' },
						{ img: '73e14fa217b077f3f4d72c819319f269_7', name: '量化交易策略类型' },
						{ img: '73e14fa217a6457394d7b961c953a2be_7', name: '交易策略' },
						{ img: '73e14fa217e89fc7e967f01881083cbc_7', name: '量化交易的应用领域' },
						{ img: '73e14fa217d76ae4f48e29f0350e5477_7', name: '情绪化交易' },
						{ img: '73e14fa217bed541d59520a9d244a1fa_7', name: '交易理念' },
						{ img: '73e14fa2170f460a2ac314c3409774c5_7', name: '什么是量化交易' },
						{ img: '73e14fa217a11c142d7815a1b5b539ec_7', name: '主观交易vs量化交易' },
						{ img: '73e14fa2175eb1a70fd6cd2220494f6b_7', name: '交易盈利的公式' },
						{ img: '73e14fa217be197a3753346078f27a71_7', name: '胜率vs盈亏比' },
						{ img: '73e14fa21738622ca533f9e0edf8fdb5_7', name: '大数定律' },
						{ img: '73e14fa21769a9c32ad5a6bd4a5aeefa_7', name: '交易要有概念思维' },
						{ img: '73e14fa2175b199e02811ea971cb9000_7', name: '交易系统的 构成要素' }
					]
    };
  },
  mounted() {
    this.$nextTick(function () {
      this.loadPlayerScript(this.loadPlayer);
    });
  },
  methods: {
    loadPlayerScript(callback) {
      if (!window.polyvPlayer) {
        const myScript = document.createElement("script");
        myScript.setAttribute("src", this.vodPlayerJs);
        myScript.onload = callback;
        document.body.appendChild(myScript);
      } else {
        callback();
      }
    },
    loadPlayer() {
      const polyvPlayer = window.polyvPlayer;
      this.mgList.forEach((item) => {
        this.player = polyvPlayer({
          wrap: "#player" + item.img,
          width: 360,
          height: 203,
          vid: item.img,
        });
      });
    },
  },
  destroyed() {
    if (this.player) {
      this.player.destroy();
    }
  },
};
</script>
<style lang="less" scoped>
.img-list {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 30% 30% 30%;
  grid-row-gap: 82px;
  grid-column-gap: 5%;
  padding: 65px 0;
  box-sizing: border-box;
  .img-item {
    width: 100%;
    // padding-bottom: 82px;
    .myimg {
      width: 100%;
      img {
        width: 100%;
        display: block;
      }
    }
    .myname {
      width: 100%;
      height: 46px;
      background-color: #f5f4f9;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>